<template lang="pug">
    .management-twitter(v-bind:class="{'management-twitter--hidden' : MinimizeTwitter}")
        //- b-loading(v-if="status_list_topics.status === 'loading'" text="Menuat data")

        button.btn-toggle-twitter(@click="toggleTwitter()")
            template(v-if="!MinimizeTwitter")
                b-icon(name="chevrons-right")
            template(v-else)
                b-icon(name="chevrons-left")

        //- template(v-if="status_list_trend.status === 'loading'")
            b-loading.mt-10(text="Memuat data")
            .management-twitter__header
            .management-twitter__body.relative
            .detail-twitter(v-bind:class="{'detail-twitter--opened' : false}")
            .detail-twitter(v-bind:class="{'detail-twitter--opened' : false}")


        //- template(v-else)
        .management-twitter__header
            ul.tabs
                li: button.tab(:class="activeSectionTrending ? 'tab--active' : '' " @click="tabToggle('tabTrending')") Isu Teramai
                li: button.tab(:class="activeSectionTweets  ? 'tab--active' : '' " @click="tabToggle('tabTweet')") Feeds
        .management-twitter__body.relative
            //- template(v-if="status_list_trending.status === 'loading'")
            //-     b-loading(text="Loading")
            //- template(v-else)
            .box-popular(v-show="activeSectionTrending")
                template(v-if="status_list_trending.status === 'loading'")
                    b-loading(text="Memuat isu teramai..." kind="circular")

                .popular-issue(v-else-if="list_trending && list_trending.items && list_trending.items.length > 0")
                    //- button.popular-issue__item(v-for="item in list_trending.items" @click="detailBoxIssue = !detailBoxIssue")
                    button.popular-issue__item(v-for="item in list_trending.items" @click="getDetailBoxTrending(item.title)")
                        strong.block.mb-05 {{ item.title}}
                        span.block.mb-2 {{ numberWithDot(item.count) }}
                        .bar( :style="'width:' + item.count / maxCount * 100 + '%'")
                        //- .bar( :style="'width:' + ((item.count / maxCount * 95)+5) + '%'")
                .absolute.w-full.h-full.inset-0.flex.items-center.justify-center(v-else)
                    .block
                        b-icon.mb-4(name="info" size="2x")
                        p Isu Teramai tidak ditemukan.

            .box-tweets(v-show="activeSectionTweets")

                .filter-socmed
                    span Menampilkan
                    .field.field--icon-right.w-full
                        select.field-style(v-model="type_feed" @change="changeTypeSocmed('list_feed')")
                            option(value="1,2,3,4") Semua Sosial Media
                            option(value="3") Facebook
                            option(value="1") Twitter
                            option(value="4") Instagram
                            option(value="2") YouTube
                        span.icon-field
                            b-icon(name="chevron-down")
                .p-2(v-if="list_data_feed.length > 0 && status_list_feed.status != 'loading'" )
                    .label-alert.rounded
                        .label-alert__cover
                            b-icon(name="calendar")
                        .label-alert__info.text-xs
                            p Data {{ wordingListFeed }} diambil dari rentang waktu <strong> {{ datetimeFormat(list_feed.optional_data.first_update,'DD-MM-YYYY HH:mm:ss', 'DD MMM YYYY HH:mm') }} - {{ datetimeFormat(list_feed.optional_data.last_update,'DD-MM-YYYY HH:mm:ss', 'DD MMM YYYY HH:mm') }} </strong>
                .tweets
                    button.tweets__item.account(v-if="list_data_feed.length > 0" v-for="item in list_data_feed")
                        PostFeed(
                            :photo="item.extra_author_attributes.photo"
                            :username="item.extra_author_attributes.username"
                            :date="datetimeFormat(item.created_at,'DD-MM-YYYY HH:mm:ss', 'DD/MM/YY')"
                            :content="item.content"
                            :url="item.url"
                            :socmed="item.source_type.toLowerCase()"
                        )

                    //- button.tweets__item.account(v-for="item in list_data_feed" @click="SocialMediaTwitter = !SocialMediaTwitter")
                    //- button.tweets__item.account(v-for="item in list_data_feed" @click="getProfileTwitter(item.extra_author_attributes.username)")
                        PostTweet(
                            :photo="item.extra_author_attributes.photo"
                            :username="'@'+item.extra_author_attributes.username"
                            :date="datetimeFormat(item.created_at,'DD-MM-YYYY HH:mm:ss', 'DD/MM/YY')"
                            :content="item.content"
                        )
                    //- .pagination.pagination--small.p-4
                    template(v-if="list_data_feed.length > 0 && status_list_feed.status != 'loading'")
                        v-pagination.p-4(v-model="page_feed" :page-count="list_feed.total_page" :classes="paginationClasses" :labels="paginationLabels")
                    //- .pagination.pagination--small.p-4(v-if="status_list_feed.status !== 'loading'")
                        b-button.btn--disabled(icon_only="chevron-left" kind="tertiary")
                        //- active
                        b-button.pagination-btn.btn--active(text="1" kind="primary")
                        b-button.pagination-btn(text="2" kind="tertiary")
                        b-button.pagination-btn(text="3" kind="tertiary")
                        b-button.pagination-btn.btn--disabled(text=". . .")
                        b-button.pagination-btn(text="100" kind="tertiary")
                        b-button(icon_only="chevron-right" kind="tertiary")
                template(v-if="list_data_feed.length > 0 || status_list_feed.status == 'loading'")
                    .absolute.inset-0.w-full.h-full.flex.items-center.justify-center(v-if="status_list_feed.status === 'loading'")
                        b-loading(:text="'Memuat '+ wordingListFeed +'...'" kind="circular")
                    template(v-else)
                        .flex.items-center.justify-center.w-full.p-4.hidden
                            b-button(
                                kind="tertiary"
                                :text="'Muat '+ wordingListFeed +' Lainnya'"
                                size="small"
                                v-if="list_data_feed.length < parseInt(list_feed.total_item)"
                                @click.native="loadMoreFeed()"
                            )
                        .count-twitter Menampilkan {{ numberWithDot(limit_feed*(page_feed-1)+1) }} - {{ numberWithDot(limit_feed*(page_feed-1)+list_data_feed.length) }} dari {{ numberWithDot(list_feed.total_item) }} {{ wordingListFeed }}

                .absolute.w-full.h-full.inset-0.flex.items-center.justify-center(v-else)
                    .block
                        b-icon.mb-4(name="info" size="2x")
                        p {{ wordingListFeed }} tidak ditemukan.

        //- Detail Twitter
        .detail-twitter(v-bind:class="{'detail-twitter--opened' : SocialMediaTwitter}")
            .detail-twitter__header
                .flex-grow
                    template(v-if="this.status_detail_profile.status === 'loading'")
                        .block.h-4.w-56.relative
                            b-loading(kind="skeleton")
                    template(v-else)
                        p @{{ selectedUsername  }}

                b-button(icon_only="x" @click.native="SocialMediaTwitter = !SocialMediaTwitter")
            .detail-twitter__body.relative
                template(v-if="this.status_detail_profile.status === 'loading'")
                    b-loading(text="Memuat akun")
                template(v-else)
                    //- .hidden
                        snapShotAccount(
                            photo="https://pbs.twimg.com/profile_images/1341056153468354574/kcfQnHeI_400x400.jpg",
                            name="Pandji Pragiwaksono",
                            followers="2.5M",
                            location="Indonesia",
                            link="linktr.ee/pandji.pragiwaksono",
                            bday="18 juni"
                            bio="Stand-Up Comedian with 8 Special who is now running COMIKA, a company devoted to create the world a funnier place."
                        )
                        .box-acocunt-following.mt-4
                            .border-b.pb-2.mb-2
                                span Mengikuti <strong> 314 </strong> akun
                            ul
                                li.py-1.transition-all(v-for="item in following"): router-link.link.w-full( :to="item.url"): span {{ item.account }}

                    .box-account
                        template(v-if="status_detail_profile.status === 'loading' || status_detail_following.status === 'loading'")
                            b-loading.b-loading-inherit(kind="circular")
                        template(v-else)
                            .box-account__cover
                                img(:src="checkString(detail_profile.photo,detail_following.photo)?checkString(detail_profile.photo,detail_following.photo):getDefaultImage" @error="getErrorImage")
                            .box-account__info
                                strong.block.mb-1 @{{ checkString(detail_profile.username, detail_following.username) }}
                                span {{ checkString(detail_profile.name, detail_following.name) }}

                    .box-account-detail
                        .box-account-detail__header
                            ul.tabs
                                li: button.tab(:class="accountInfo ? 'tab--active' : '' " @click="accountToggle('information')") Informasi
                                li: button.tab(:class="accountfollowing ? 'tab--active' : '' " @click="accountToggle('following')") Mengikuti
                        .box-account-detail__body
                            .p-4(v-show="accountInfo")
                                template(v-if="status_detail_profile.status === 'loading'")
                                    b-loading.b-loading-inherit(kind="circular")
                                .snapshot.snapshot--align-left(v-else)
                                    figure.snapshot__account
                                        //- NOTE sementara di hide karena data tidak sesuai
                                        //- .followers
                                            strong(style="padding-right:4px") {{ shortNumber(checkString(detail_profile.followers_count, detail_following.followers_count)) }}
                                            span Pengikut

                                    ul.snapshot__info
                                        li
                                            b-icon.mb-2.text-gray-400(name="map-pin")
                                            span {{ checkString(detail_profile.location, detail_following.location) }}
                                        li
                                            b-icon.mb-2.text-gray-400(name="link")
                                            span: a.link(:href="checkString(detail_profile.url, detail_following.url)" target="_blank") {{ checkString(detail_profile.url, detail_following.url) }}
                                            //- span {{ detail_profile.url }}
                                        //- li
                                            b-icon.mb-2.text-gray-400(name="gift")
                                            span 18 juni

                                    .snapshot__bio
                                        p.bio {{ checkString(detail_profile.description, detail_following.description) }}
                            .p-4(v-show="accountfollowing")
                                template(v-if="status_detail_following.status === 'loading'")
                                    b-loading.b-loading-inherit(kind="circular")
                                template(v-else)
                                    strong Mengikuti {{ detail_following.following_count }} akun
                                    ul
                                        li.py-1.transition-all(v-for="item in detail_following.list_following")
                                            a.link.w-full( :href="item.url" target="_blank"): span {{ item.name }}


        .detail-twitter(v-bind:class="{'detail-twitter--opened' : detailBoxIssue}")
            .detail-twitter__header.flex.items-center.justify-between.border-b.border-gray-100.px-4.py-3
                .flex-grow
                    template(v-if="this.status_list_feed.status === 'loading'")
                        .block.h-4.w-56.relative
                            b-loading(kind="skeleton")
                    template(v-else)
                        p {{ selectedTrending }}

                b-button(icon_only="x" @click.native="detailBoxIssue = !detailBoxIssue")
            .detail-twitter__body.relative
                .filter-socmed
                    span Menampilkan
                    .field.field--icon-right.w-full
                        select.field-style(v-model="type_feed_trending" @change="changeTypeSocmed('detail_trending')")
                            option(value="1,2,3,4") Semua Sosial Media
                            option(value="3") Facebook
                            option(value="1") Twitter
                            option(value="4") Instagram
                            option(value="2") YouTube
                        span.icon-field
                            b-icon(name="chevron-down")

                .tweets(v-if="list_data_feed_trending.length > 0")
                    button.tweets__item.account(v-for="item in list_data_feed_trending")
                        PostFeed(
                            :photo="item.extra_author_attributes.photo"
                            :username="item.extra_author_attributes.username"
                            :date="datetimeFormat(item.created_at,'DD-MM-YYYY HH:mm:ss', 'DD/MM/YY')"
                            :content="item.content"
                            :url="item.url"
                            :socmed="item.source_type.toLowerCase()"
                            :openDetail="false"
                        )
                //- .tweets.pointer-events-none(v-if="list_data_feed_trending.length > 0")
                    //- .tweets__item.pointer-events-none(v-for="item in relatedTweets" @click="SocialMediaTwitter = !SocialMediaTwitter")
                        PostTweet(
                            :photo="item.avatar"
                            :username="item.username"
                            :date="item.date"
                            :content="item.content"
                        )
                    .tweets__item.pointer-events-none(v-for="item in list_data_feed_trending" @click="SocialMediaTwitter = !SocialMediaTwitter")
                        PostTweet(
                            :photo="item.extra_author_attributes.photo"
                            :username="'@'+item.extra_author_attributes.username"
                            :date="datetimeFormat(item.created_at,'DD-MM-YYYY HH:mm:ss', 'DD/MM/YY')"
                            :content="item.content"
                        //- )
                template(v-if="list_data_feed_trending.length > 0 || status_list_feed.status == 'loading'")
                    .relative.mt-10(v-if="status_list_feed.status === 'loading'")
                        b-loading(:text="'Memuat '+ wordingListFeedTrending +'...'" kind="circular")
                    template(v-else)
                        v-pagination.p-4(v-model="page_feed_trending" :page-count="list_feed_trending.total_page" :classes="paginationClasses" :labels="paginationLabels")
                        //- .flex.items-center.justify-center.w-full.p-4
                            b-button(
                                kind="tertiary"
                                text="Muat Feeds Lainnya"
                                size="small"
                                v-if="list_data_feed_trending.length < parseInt(list_feed_trending.total_item)"
                                @click.native="loadMoreFeedTrending()"
                            )
                        //- .count-twitter Menampilkan 1 - {{ numberWithDot(list_data_feed_trending.length) }} dari {{ numberWithDot(list_feed_trending.total_item) }} {{ wordingListFeedTrending }}
                        .count-twitter Menampilkan {{ numberWithDot(limit_feed_trending*(page_feed_trending-1)+1) }} - {{ numberWithDot(limit_feed_trending*(page_feed_trending-1)+list_data_feed_trending.length) }} dari {{ numberWithDot(list_feed_trending.total_item) }} {{ wordingListFeedTrending }}

                //- .flex.items-center.justify-center.w-full.p-4(v-else)
                .absolute.w-full.h-full.inset-0.flex.items-center.justify-center(v-else)
                    .block
                        b-icon.mb-4(name="info" size="2x")
                        span {{ wordingListFeedTrending }} tidak ditemukan.

        //- DETAIL ACCOUNT SOCIAL MEDIA  === FACEBOOK
        .account-social-media.account-social-media--facebook(:class="{'account-social-media--opened' : SocialMediaFacebook}")
            .account-social-media__header
                .flex-grow
                    p {{ selectedUsername }}

                b-button(icon_only="x" @click.native="SocialMediaFacebook = false")

            .account-social-media__body.scrollbox
                .scrollbox-container
                    template(v-if="this.status_detail_profile.status === 'loading'")
                        .social-media--negative-state
                            b-loading(kind="circular" :text="'Memuat Informasi ' + selectedUsername +' ...'")
                    template(v-else)
                        .social-media-detail
                            .social-media-detail__header
                                .social-media-account-info
                                    figure.social-media-account-info__cover
                                        img.avatar-account(:src="detail_profile.photo" @error="getErrorImage($event,'profile')")
                                        .label-socmed
                                            img(src="/images/socmed/fb.png", alt="Facebook")

                                    .social-media-account-info__detail
                                        //- name
                                        strong.block.mb-1 {{  detail_profile.name }}
                                        //- username
                                        span {{  detail_profile.username }}

                            .social-media-detail__body
                                .callout
                                    span Deskripsi
                                    p {{  detail_profile.description }}

                                .callout
                                    span No. Handphone
                                    p {{  checkString(detail_profile.phone) }}

                                //- .callout
                                    span Pendidikan
                                    p SMP PIRI 2

                                .callout
                                    span Jenis Kemalin
                                    p {{  checkString(detail_profile.gender) }}

                                //- .callout
                                    span Usia
                                    p 26 tahun

                                .callout
                                    span Lokasi
                                    p {{  checkString(detail_profile.location) }}

                                .callout
                                    span Total Pertemanan
                                    p {{ shortNumber(detail_profile.following_count) }}

                                .callout
                                    span Total Pengikut
                                    p {{ shortNumber(detail_profile.followers_count) }}
                                a(:href="detail_profile.url" target="_blank")
                                    b-button(text="Kunjungi profil Facebooknya" kind="tertiary" size="small")

                    //- template(v-else)
                        .social-media--negative-state
                            .empty-state
                                b-icon.mb-4(name="info" size="2x")
                                p Terjadi kesalahan ketika memuat informasi {Nama akun}

        //- DETAIL ACCOUNT SOCIAL MEDIA  === INSTAGRAM
        .account-social-media.account-social-media--instagram(:class="{'account-social-media--opened' : SocialMediaInstagram}")
            .account-social-media__header
                .flex-grow
                    p @{{ selectedUsername  }}
                b-button(icon_only="x" @click.native="SocialMediaInstagram = false")
            .account-social-media__body.scrollbox
                .scrollbox-container
                    template(v-if="this.status_detail_profile.status === 'loading'")
                        .social-media--negative-state
                            b-loading(kind="circular" :text="'Memuat Informasi ' + selectedUsername +' ...'")
                    template(v-else)
                        .social-media-detail
                            .social-media-detail__header
                                .social-media-account-info
                                    figure.social-media-account-info__cover
                                        img.avatar-account(:src="detail_profile.photo" @error="getErrorImage($event,'profile')")
                                        .label-socmed
                                            img(src="/images/socmed/ig.png", alt="Instagram")

                                    .social-media-account-info__detail
                                        //- name
                                        strong.block.mb-1 {{ detail_profile.name }}
                                        //- username
                                        span @{{ detail_profile.username }}

                            .social-media-detail__body
                                .callout
                                    span Deskripsi
                                    p {{ checkString(detail_profile.description) }}

                                .callout
                                    span Total Diikuti
                                    p {{ shortNumber(detail_profile.following_count) }}

                                .callout
                                    span Total Pengikut
                                    p {{ shortNumber(detail_profile.followers_count) }}

                                .callout
                                    span Jenis Kelamin
                                    p {{ checkString(detail_profile.gender) }}

                                //- tidak ada data
                                //- .callout
                                    span Usia
                                    p {{ detail_profile.age }}

                                //- lokasi dari source blm terparsing
                                //- .callout
                                    span Lokasi
                                    p {{ detail_profile.location }}

                                a(:href="detail_profile.url" target="_blank")
                                    b-button(text="Kunjungi profil Instagramnya" kind="tertiary" size="small")

                    //- template(v-else)
                        .social-media--negative-state
                            .empty-state
                                b-icon.mb-4(name="info" size="2x")
                                p Terjadi kesalahan ketika memuat informasi {Nama akun}

        //- DETAIL ACCOUNT SOCIAL MEDIA  === YOUTUBE
        .account-social-media.account-social-media--youtube(:class="{'account-social-media--opened' : SocialMediaYoutube}")
            .account-social-media__header
                .flex-grow
                    p {{ selectedUsername  }}

                b-button(icon_only="x" @click.native="SocialMediaYoutube = false")

            .account-social-media__body.scrollbox
                .scrollbox-container
                    template(v-if="this.status_detail_profile.status === 'loading'")
                        .social-media--negative-state
                            b-loading(kind="circular" :text="'Memuat Informasi ' + selectedUsername +' ...'")
                    template(v-else)
                        .social-media-detail
                            .social-media-detail__header
                                .social-media-account-info
                                    figure.social-media-account-info__cover
                                        img.avatar-account(:src="detail_profile.photo" @error="getErrorImage($event,'profile')")
                                        .label-socmed
                                            img(src="/images/socmed/yt.png", alt="Youtube")

                                    .social-media-account-info__detail
                                        //- name
                                        strong.block.mb-1 {{ detail_profile.name }}

                            .social-media-detail__body
                                .callout
                                    span Deskripsi
                                    p {{ checkString(detail_profile.description) }}

                                .callout
                                    span Total subscriber
                                    p {{ shortNumber(detail_profile.followers_count) }}

                                //- .callout
                                    span Total Video
                                    p 869

                                a(:href="detail_profile.url" target="_blank")
                                    b-button(text="Kunjungi channel Youtubenya" kind="tertiary" size="small")

                    //- template(v-else)
                        .social-media--negative-state
                            .empty-state
                                b-icon.mb-4(name="info" size="2x")
                                p Terjadi kesalahan ketika memuat informasi {Nama akun}

</template>

<script>
import {mapState} from 'vuex';
import snapShotAccount from '@/components/SnapShot';
import PostTweet from '@/components/PostTweet';
import PostFeed from '@/components/PostFeed';
import vPagination from 'vue-plain-pagination';


export default {
    name: 'ManagementTwitter',
    components: {
        snapShotAccount,
        PostTweet,
        PostFeed,
        vPagination,
    },
    data() {
        return {
            // loadingTweeter: false,
            maxCount: 700,
            activeSectionTrending: false,
            activeSectionTweets: true,

            // detail account
            SocialMediaTwitter: false,

            // detail Issue
            detailBoxIssue: false,

            // twitter
            MinimizeTwitter: false,

            // akun detail
            accountInfo: true,
            accountfollowing: false,

            // social media
            SocialMediaFacebook: false,
            // showAcountFacebook: 'show',

            SocialMediaInstagram: false,
            // showAcountInstagram: 'show',

            SocialMediaYoutube: false,
            showAcountYoutube: 'show',

            startDate: '',
            endDate: '',

            loading_issue: false,
            list_data_feed: [],
            limit_feed: 20,
            page_feed: 1,
            type_feed: '1,2,3,4',
            timeout: null,
            paginationClasses: { // http://getbootstrap.com/docs/4.1/components/pagination/
                ul: 'pagination pagination--small',
                li: 'btn pagination-btn ',
                liActive: 'btn--active',
                liDisable: 'btn--disabled',
                button: 'btn btn--normal btn--tertiary btn--modifier',
            },
            paginationLabels: {
                first: false,
                prev: '&lsaquo;',
                next: '&rsaquo;',
                last: false,
            },

            list_data_feed_trending: [],
            limit_feed_trending: 20,
            page_feed_trending: 1,
            type_feed_trending: '1,2,3,4',

            selectedTrending: '',
            selectedUsername: '',

            dataOnDetail: false,
            wordingListFeed: 'Feeds',
            wordingListFeedTrending: 'Feeds',
            arrSosmed: ['Twitter', 'YouTube', 'Facebook', 'Instagram'],
        };
    },
    computed: {
        ...mapState('trend', [
            'status_list_trend',
        ]),
        ...mapState('medsos', [
            'status_list_trending',
            'status_list_feed',
            'status_detail_following',
            'status_detail_profile',
            'list_trending',
            'list_feed',
            'list_feed_trending',
            'detail_following',
            'detail_profile',
        ]),
        // id() {
        //     return this.$route.params.id;
        // },
        data: {
            get() {
                if (this.dataOnDetail) {
                    return this.dataOnDetail;
                } else {
                    let dataTmp = {};
                    try {
                        dataTmp = this.decodeDetailData(this.$route.params.data);
                    } catch (error) {
                        console.log(error);
                    }
                    return dataTmp;
                }
            },
            set: function(newValue) {
                return newValue;
            },
        },
    },
    watch: {
        // id(newVal) {
        //     if (newVal !== undefined) {
        //         this.getData(true);
        //     }
        // },
        page_feed(newVal) {
            this.getDataFeed();
        },
        page_feed_trending(newVal) {
            this.getDataFeed(this.selectedTrending, true, false);
        },
        list_trending(newVal) {
            if (newVal.items && newVal.items.length > 0) {
                this.maxCount = newVal.items[0].count;
            }
        },
        list_feed(newVal) {
            if (newVal.items && newVal.items.length > 0) {
                for (let i = 0; i < newVal.items.length; i++) {
                    this.list_data_feed.push(newVal.items[i]);
                }
            }
        },
        list_feed_trending(newVal) {
            if (newVal.items && newVal.items.length > 0) {
                for (let i = 0; i < newVal.items.length; i++) {
                    this.list_data_feed_trending.push(newVal.items[i]);
                }
            }
        },
    },
    methods: {
        changeTypeSocmed(from) {
            if (from === 'list_feed') {
                try {
                    if (this.type_feed == '1,2,3,4') {
                        this.wordingListFeed = 'Feeds';
                    } else {
                        this.wordingListFeed = 'Feeds ' + this.arrSosmed[parseInt(this.type_feed)-1];
                    }
                } catch (error) {
                    this.wordingListFeed = 'Feeds';
                }
                this.page_feed = 1;
                this.getDataFeed();
            } else {
                try {
                    if (this.type_feed_trending == '1,2,3,4') {
                        this.wordingListFeedTrending = 'Feeds';
                    } else {
                        this.wordingListFeedTrending = 'Feeds ' + this.arrSosmed[parseInt(this.type_feed_trending)-1];
                    }
                } catch (error) {
                    this.wordingListFeedTrending = 'Feeds';
                }
                this.page_feed_trending = 1;
                this.getDataFeed(this.selectedTrending, true, false);
            }
        },
        detailProfileToogle() {
            this.detailBoxIssue = !this.detailBoxIssue;
            // get API Profile
        },
        tabToggle(tabName) {
            if (tabName == 'tabTrending') {
                this.activeSectionTrending = true;
                this.activeSectionTweets = false;
            } else {
                this.activeSectionTrending = false;
                this.activeSectionTweets = true;
            }
        },

        accountToggle(tabName) {
            if (tabName == 'information') {
                this.accountInfo = true;
                this.accountfollowing = false;
            } else {
                this.accountInfo = false;
                this.accountfollowing = true;
            }
        },

        getDetailBoxTrending(title) {
            this.selectedTrending = title;
            this.detailBoxIssue = !this.detailBoxIssue;
            this.list_data_feed_trending = [];
            this.page_feed_trending= 1;

            // this.getDataFeed('law', true, false);
            this.getDataFeed(this.selectedTrending, true, false);
        },
        getProfileTwitter(username, type) {
            this.SocialMediaTwitter = !this.SocialMediaTwitter;
            this.getDataFollowing(username);
            this.getDataProfile(username, type);
        },
        getProfileFacebook(username, type) {
            this.SocialMediaFacebook = !this.SocialMediaFacebook;
            this.getDataProfile(username, type);
        },
        getProfileInstagram(username, type) {
            this.SocialMediaInstagram = !this.SocialMediaInstagram;
            this.getDataProfile(username, type);
        },
        getProfileYoutube(username, type) {
            this.SocialMediaYoutube = !this.SocialMediaYoutube;
            this.getDataProfile(username, type);
        },

        // loadingIssue() {
        //     const self = this;
        //     this.loading_issue = true;
        //     setTimeout(function() {
        //         self.loading_issue = false;
        //     }, 800);
        // },

        toggleTwitter() {
            Event.$emit('ctaTwitterHidden');
            this.MinimizeTwitter = !this.MinimizeTwitter;
        },
        loadMoreFeed() {
            this.page_feed += 1;
            this.getDataFeed();
        },
        loadMoreFeedTrending() {
            this.page_feed_trending += 1;
            // this.getDataFeed('law', true, false);
            this.getDataFeed(this.selectedTrending, true, false);
        },
        getDataFeed(title='', fromTrending = false, withSlug=true) {
            // get list tweet
            let slug = '';
            let limit = this.limit_feed;
            let page = this.page_feed;
            let type = this.type_feed;
            if (withSlug) {
                slug = this.data.slug;
                this.list_data_feed = [];
            }
            if (fromTrending) {
                limit = this.limit_feed_trending;
                page = this.page_feed_trending;
                type = this.type_feed_trending;
                this.list_data_feed_trending = [];
            }
            this.$store.dispatch('medsos/getListFeed', [limit, page, this.startDate, this.endDate, slug, title, fromTrending, type] );
        },
        getDataTrending() {
            // get trending
            this.$store.dispatch('medsos/getListTrending', [this.startDate, this.endDate] );
        },
        getDataProfile(username, type) {
            // get profile
            this.$store.dispatch('medsos/getDetailProfile', [username, type] );
        },
        getDataFollowing(username) {
            // get trending
            this.$store.dispatch('medsos/getListFollowing', [username] );
        },
        getData() {
            this.getDataFeed();
            this.getDataTrending();
        },
    },
    created() {
        Event.$on('getDataTwitter', (data, status, startDate='', endDate='') => {
            this.dataOnDetail = data;
            this.startDate = startDate;
            this.endDate = endDate;
            this.getData(status);
        });

        Event.$on('componentsOpenAccount', (type, username) => {
            this.selectedUsername = username;
            this.selectedDetailSosmed = type;
            if (type == 'twitter') {
                this.getProfileTwitter(username, type);
            } else if (type == 'facebook') {
                this.getProfileFacebook(username, type);
            } else if (type == 'instagram') {
                this.getProfileInstagram(username, type);
            } else {
                this.getProfileYoutube(username, type);
            }
        });
    },
};
</script>

<style lang="sass">
</style>
